import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import '../styles/app.css';
import Projects from './Projects';

class App extends Component {
  render() {
    return (
      <div>
        <div className={'container'}>
          <Switch>
            <Route exact path="/" component={Projects}/>
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
