import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom';

import './styles/index.css';
import registerServiceWorker from './registerServiceWorker';
import App from './components/App';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" component={App}/>
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'));
registerServiceWorker();
